<template>
  <v-container>
    <v-row class="primary--text ma-5">
      <v-col cols="12" sm="4" class="text-center">
        <h1 style="font-size: 44px">
          {{ spent.total | toEur }}
        </h1>
        <p style="font-size: small">{{ $t("spent") }}</p>
      </v-col>
      <v-col cols="12" sm="4" class="text-center">
        <h1 style="font-size: 44px">
          {{ spent.remaining | toEur }}
        </h1>
        <p style="font-size: small">{{ $t("remaining") }}</p>
      </v-col>
      <v-col cols="12" sm="4" class="text-center">
        <h1 style="font-size: 44px">{{ spent.count }}</h1>
        <p style="font-size: small">{{ $t("total_number") }}</p>
      </v-col>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="messages"
      class="elevation-5 custom-table2"
      :no-data-text="$t('no_data')"
      :search="search"
      :loading="loading"
      mobile-breakpoint="0"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title
            ><v-text-field
              v-model="search"
              append-icon="search"
              :label="$t('search_txt')"
              single-line
              hide-details
              filled
              dense
              rounded
              class="mx-auto"
              style="max-width: 500px"
              clearable
            ></v-text-field
          ></v-toolbar-title>
          <v-spacer></v-spacer>

          <v-btn color="primary" class="mb-2" @click="initMessage(null)">
            {{ $t("new_message") }}
          </v-btn>
          <v-dialog v-model="dialogDelete" max-width="550px">
            <v-card>
              <v-card-title
                class="headline"
                v-text="$t('delete_message_confirm')"
              />
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="dialogDelete = false">
                  {{ $t("cancel") }}
                </v-btn>
                <v-btn color="primary" @click="deleteMessage()">
                  {{ $t("delete") }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:[`item.created_at`]="{ item }">
        {{ item.created_at | moment }}
      </template>
      <template v-slot:[`item.updated_at`]="{ item }">
        {{ item.updated_at | moment }}
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn icon @click="showMessage(item)">
          <v-icon>remove_red_eye</v-icon>
        </v-btn>
        <v-btn icon class="mr-2" color="primary" @click="initMessage(item)">
          <v-icon>edit</v-icon>
        </v-btn>

        <v-btn color="red darken-3" @click="initDelete(item)" icon>
          <v-icon>delete</v-icon>
        </v-btn>
      </template>
    </v-data-table>

    <v-dialog persistent v-model="dialog" width="550px">
      <v-card>
        <v-card-title class="headline" v-text="$t(title)" />
        <v-card-text>
          <v-form ref="sms_form" lazy-validation>
            <v-textarea
              :label="$t('message')"
              v-model="content.text"
              ref="message_txt"
              counter
              :rules="rules"
              required
              solo
              :style="[isLtr ? { direction: 'ltr' } : { direction: 'rtl' }]"
            ></v-textarea>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-switch v-model="isLtr" :label="$t('english')" class="me-2" />
          <v-btn color="primary" outlined @click="addName">{{
            $t("add_name")
          }}</v-btn>
          <v-btn color="primary" outlined @click="testDialog = true">{{
            $t("send_test")
          }}</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog = false">
            {{ $t("cancel") }}
          </v-btn>
          <v-btn color="primary" @click="save()">
            {{ $t("save") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="testDialog" width="500" content-class="dialog_index">
      <v-card>
        <v-card-title class="headline" v-text="$t('enter_test_mobile')" />
        <v-card-text>
          <vue-tel-input
            ref="phoneNumber"
            v-bind="telOptions"
            style="direction: ltr"
          ></vue-tel-input>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="testDialog = false">
            {{ $t("cancel") }}
          </v-btn>
          <v-btn color="primary" @click="sendTest">
            {{ $t("send") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogShow" width="700">
      <v-card>
        <v-card-text
          style="white-space: pre-wrap; padding-top: 20px; color: black"
        >
          <div :style="[isLtr ? { direction: 'ltr' } : { direction: 'rtl' }]">
            {{ content.text }}
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-switch v-model="isLtr" :label="$t('english')" class="me-2" />
          <v-btn color="primary" text @click="dialogShow = false">
            {{ $t("ok") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { message, messageSpent, sendTestMessage } from "@/api/URLs";
import { getMessagesH } from "@/api/headers";
import { VueTelInput } from "vue-tel-input";

export default {
  name: "Messages",
  components: {
    VueTelInput,
  },
  data() {
    return {
      messages: [],
      search: null,
      spent: { total: 0, count: 0, remaining: 0 },
      content: { number: null, country: null, text: "" },
      loading: false,
      dialog: false,
      dialogShow: false,
      title: "",
      isLtr: false,
      testDialog: false,
      rules: [(value) => !!value || this.$t("this_required")],
      headers: getMessagesH(),
      dialogDelete: false,
      telOptions: {
        mode: "national",
        preferredCountries: [
          "IQ",
          "AE",
          "DZ",
          "BH",
          "TD",
          "JO",
          "KW",
          "LB",
          "LY",
          "MA",
        ],
        validCharactersOnly: true,
        inputOptions: {
          showDialCode: false,
          tabindex: 0,
          placeholder: this.$t("mobile"),
        },
        dropdownOptions: {
          disabledDialCode: false,
          tabindex: 0,
          showFlags: true,
          showDialCodeInSelection: true,
        },
      },
    };
  },
  created() {
    this.getAll();
    this.spentAll();
  },
  watch: {
    "$root.$i18n.locale": function () {
      this.headers = getMessagesH();
    },
  },
  methods: {
    getAll() {
      this.loading = true;
      let self = this;
      this.$http({
        method: "get",
        url: message,
      }).then(function (response) {
        console.log(response);
        if (response.data.success) self.messages = response.data.data;
        self.loading = false;
      });
    },
    initMessage(item) {
      this.content = {};
      if (item === null) this.title = "create_new_message";
      else {
        this.content = Object.assign({}, item);
        this.title = "edit_message";
      }
      this.dialog = true;
    },
    addName() {
      if (this.content.text === undefined) return;

      const el = this.$refs.message_txt.$el.querySelector("textarea");
      let cursorPos = el.selectionEnd;

      this.content.text =
        this.content.text.substring(0, cursorPos) +
        "{{%NAME%}}" +
        this.content.text.substring(cursorPos);
      cursorPos += this.content.text.length;
      this.$nextTick(() => el.setSelectionRange(cursorPos, cursorPos));
    },
    sendTest() {
      let phone = this.$refs.phoneNumber.phoneObject;

      if (phone.valid) {
        this.loading = true;
        let self = this;
        self.content.number = phone.number;

        this.$http({
          method: "post",
          url: sendTestMessage,
          data: self.content,
        }).then(function (response) {
          console.log(response);
          if (response.data.success) {
            self.spent = response.data.data;
            self.testDialog = false;
            self.$store.commit("showToast", "sent_successfully");
          }
          self.loading = false;
        });
      } else this.$store.commit("showToast", "phone_incorrect");
    },
    save() {
      if (this.$refs.sms_form.validate()) {
        this.loading = true;
        let self = this;
        this.$http({
          method: "post",
          url: message,
          data: self.content,
        }).then(function (response) {
          console.log(response);
          if (response.data.success) {
            if (self.content.id != null && self.content.id > 0) {
              let index = self.messages
                .map(function (x) {
                  return x.id;
                })
                .indexOf(self.content.id);

              self.$set(self.messages, index, response.data.data);
            } else self.messages.unshift(response.data.data);
            self.dialog = false;
          }
          self.loading = false;
        });
      }
    },
    initDelete(item) {
      this.content = Object.assign({}, item);
      this.dialogDelete = true;
    },
    deleteMessage() {
      this.loading = true;
      let self = this;
      this.$http({
        method: "delete",
        url: message + "/" + self.content.id,
      }).then(function (response) {
        console.log(response);
        if (response.data.success) {
          self.dialogDelete = false;
          self.messages.splice(
            self.messages
              .map(function (x) {
                return x.id;
              })
              .indexOf(self.content.id),
            1
          );
        }
        self.loading = false;
      });
    },
    spentAll() {
      let self = this;
      this.$http({
        method: "get",
        url: messageSpent,
      }).then(function (response) {
        console.log(response);
        if (response.data.success) {
          self.spent = response.data.data;
        }
      });
    },
    showMessage(item) {
      this.content = item;
      this.dialogShow = true;
    },
  },
};
</script>

<style scoped></style>
